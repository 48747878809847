import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  StackDivider,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import Helmet from "react-helmet";
import Heroblock from "../components/Heroblock";
import IntroComponent2colWhite from "../components/IntroComponent2colWhite";
import IntroComponent2colWhiteData from "../components/IntroComponent2colWhiteData";
import Line from "../components/Line";
import PortfolioGrid from "../components/PortfolioGrid";
import PortfolioTeaser from "../components/PortfolioTeaser";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation";
import GImage from "../DefinitionComponents/Utility/GImage";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";
const Targetpage = ({ pageContext }) => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });
  return (
    <>
      <Helmet>
        <title>tokentus | {pageContext.title}</title>
        <meta name="description" content={pageContext.metadescription} />
      </Helmet>
      <Heroblock
        bgColor="black"
        justifyContent={"end"}
        mediaOpacity={0.7}
        video={true}
        minH={"90vh"}
        maxH={{ lg: "90vh", md: "auto" }}
        mediaSrc={"target.mp4"}
      >
        <Box w="100%" py={16}>
          <Container variant="layoutContainer">
            <Box px={{ base: 0, lg: 8 }}>
              <FadeInAnimation
                duration={1}
                delay={0}
                initialY={-20}
                threshold={0.4}
                mt={8}
                mb={8}
              >
                <GImage
                  src={pageContext.image}
                  alt={""}
                  objectFit={"cover"}
                  top={0}
                  left={0}
                  opacity={1}
                  w={{ base: "30%", lg: "12%" }}
                />
              </FadeInAnimation>
              <InViewTrigger threshold={0.4} triggerOnce={true}>
                {(inView) => (
                  <Line
                    duration={2}
                    delay={0}
                    inView={inView}
                    bg={"white"}
                    h={"1px"}
                    maxW={"70%"}
                    mb={8}
                  />
                )}
              </InViewTrigger>
              <Text
                color="brand.white"
                fontSize={{ base: "5xl", lg: "6xl" }}
                maxW={{ base: "100%", lg: "100%" }}
                lineHeight={"short"}
                mb={3}
              >
                <FadeInAnimation
                  duration={1}
                  delay={0}
                  initialY={-20}
                  threshold={0.4}
                  mt={8}
                  mb={8}
                >
                  {pageContext.headline1}
                </FadeInAnimation>
              </Text>
              <Text
                color="brand.medlightgray"
                fontSize={{ base: "md", lg: "xl" }}
                maxW={{ base: "100%", lg: "60%" }}
                lineHeight={"short"}
                mb={3}
              >
                {pageContext.subtitle2}
              </Text>
              <FadeInAnimation
                duration={1}
                delay={0}
                initialY={-20}
                threshold={0.4}
                mt={8}
                mb={8}
                display="flex"
                alignItems="stretch"
              >
                <GImage
                  src={pageContext.flag}
                  alt={""}
                  h={"100%"}
                  objectFit={"cover"}
                  top={0}
                  display="inline-block"
                  left={0}
                  opacity={1}
                  w={{ base: "24px", lg: "56px" }}
                />
                {pageContext.weblink ? (
                  <RouterLink
                    target="_blank"
                    isExternal={true}
                    href={pageContext.weblink}
                  >
                    <Button
                      ml={4}
                      variant="solid"
                      bg="brand.green"
                      color="brand.darkgray"
                    >
                      {pageContext.title}
                    </Button>
                  </RouterLink>
                ) : null}
              </FadeInAnimation>
            </Box>
          </Container>
        </Box>
      </Heroblock>
      <Box bg="brand.black" pt={16}>
        <Container
          variant="layoutContainer"
          px={{ base: "0", lg: "0" }}
          py={20}
        >
          <Box w={{ lg: "100%", base: "100%" }}>
            <InViewTrigger threshold={0.4} triggerOnce={false}>
              {(inView) => (
                <Stack
                  direction={{ base: "column", md: "row" }}
                  divider={<StackDivider borderColor="gray.200" />}
                  spacing={4}
                  align="stretch"
                >
                  <Box minW={"50%"}>
                    <MaskSlidInAnimation threshold={1} duration={0.6}>
                      <Heading
                        fontSize="5xl"
                        fontWeight="normal"
                        mb="4"
                        w={"100%"}
                        letterSpacing="tighter"
                        color="brand.green"
                      >
                        Business Model.
                      </Heading>
                    </MaskSlidInAnimation>
                  </Box>
                  <Box>
                    <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                      <Heading
                        fontSize="2xl"
                        fontWeight="normal"
                        mb="4"
                        color="brand.green"
                      >
                        {pageContext.businessModelHeadline}
                      </Heading>
                    </FadeInAnimation>
                    <FadeInAnimation threshold={0.4} duration={1} delay={0.8}>
                      <Heading fontSize="xl" fontWeight="normal" color="white">
                        {pageContext.businessModel}
                      </Heading>
                    </FadeInAnimation>
                  </Box>
                </Stack>
              )}
            </InViewTrigger>
          </Box>
        </Container>
      </Box>
      <Box w="100%" bg="brand.black" pb={16} mb={16}>
        <Container
          variant="layoutContainer"
          px={{ base: "0", lg: "0" }}
          py={20}
        >
          <Box w={{ lg: "100%", base: "100%" }}>
            <InViewTrigger threshold={0.4} triggerOnce={false}>
              {(inView) => (
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={4}
                  align="stretch"
                >
                  <Box minW={"50%"}>
                    <MaskSlidInAnimation threshold={1} duration={0.6}>
                      <Heading
                        fontSize="5xl"
                        fontWeight="normal"
                        mb="4"
                        w={"100%"}
                        letterSpacing="tighter"
                        color="brand.green"
                      >
                        Our investment history.
                      </Heading>
                    </MaskSlidInAnimation>
                  </Box>
                  <Box w={{ base: "100%", lg: "50%" }}>
                    <FadeInAnimation
                      duration={1}
                      delay={0.25}
                      initialY={20}
                      threshold={0.4}
                    >
                      <Box
                        className="dataTable"
                        color="brand.white"
                        pr={12}
                        dangerouslySetInnerHTML={{
                          __html: pageContext.tableData,
                        }}
                      />
                    </FadeInAnimation>
                    <FadeInAnimation
                      duration={1}
                      delay={0.25}
                      initialY={20}
                      threshold={0.4}
                    >
                      <Text
                        fontSize="xs"
                        textAlign="left"
                        mt={12}
                        maxW={{ base: "100%", lg: "100%" }}
                        color="brand.medgray"
                      >
                        The compilation of the above information is based on
                        publicly available information and/or information shared
                        by the founders.
                        <br /> Furthermore, we adhere to our contractual
                        confidentiality agreements with our portfolio companies.
                      </Text>
                    </FadeInAnimation>
                  </Box>
                </Stack>
              )}
            </InViewTrigger>
          </Box>
        </Container>

        {pageContext.coinvestedby ? (
          <IntroComponent2colWhiteData
            headline="Co-Investors."
            text1={`We have invested with the following companies into ${pageContext.title}, among others.`}
            text2={`${pageContext.coinvestedby}.`}
          />
        ) : null}
        {pageContext.coinvestedby2 ? (
          <IntroComponent2colWhiteData
            headline="Co-Investors."
            text1={`${pageContext.coinvestedby2}.`}
          />
        ) : null}
      </Box>

      <PortfolioGrid />
      <PortfolioTeaser bgvariant="dark" />
    </>
  );
};

export default Targetpage;
